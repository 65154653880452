/* Constants */
:root {
  --background: #000000;
  --accent: #1A3C34; /* Dark teal */
  --text-light: #FFFFFF;
  --text-dark: #1A3C34;
  --card-bg: #1A1A1A;
  --text-muted: #808080;
  --teal-glow: #1A3C34;
  --font-size-small: 1rem;
  --font-size-medium: 1.25rem;
  --font-size-large: 2.5rem;
  --breakpoint-small: 400px;
  --breakpoint-medium: 800px;
  --breakpoint-large: 1400px;
}

/* Keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Styles */
.section-wrapper {
  width: 100%;
  min-height: 80vh;
  padding: clamp(2rem, 5vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background: var(--background); /* Fallback color */
  animation: fadeIn 1s ease-out;
  overflow: hidden;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure video covers the section */
  z-index: 0; /* Video behind everything */
  transform: scale(1.2); /* Slightly zoomed */
  transition: opacity 0.5s ease-out;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure image covers the section if used */
  z-index: 0; /* Image behind everything */
  transform: scale(1.2); /* Slightly zoomed */
}

.content-container {
  position: relative;
  z-index: 2; /* Above video/image and overlay */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text */
  width: 100%;
  max-width: var(--breakpoint-large);
  padding: 0 clamp(0.5rem, 2vw, 2rem);
}

.hero-title {
  font-size: clamp(3rem, 6vw, 6rem);
  font-weight: 800;
  padding: 0;
  margin: 0 0 clamp(0.5rem, 1vw, 1rem) 0;
  line-height: 1.2;
  text-shadow: 0 0 10px rgba(26, 60, 52, 0.5);
}

.hero-subtitle {
  font-size: clamp(1rem, 1.8vw, 1.8rem);
  color: #FFFFFF;
  max-width: clamp(400px, 60vw, 700px);
  margin: 0 0 clamp(0.5rem, 1vw, 1rem) 0;
  opacity: 0.9;
  line-height: 1.6;
}

.action-button {
  padding: clamp(0.8rem, 1.5vw, 1.2rem) clamp(1.5rem, 3vw, 3rem);
  background: var(--teal-glow);
  border: none;
  border-radius: 50px;
  font-size: clamp(1rem, 1.5vw, var(--font-size-medium));
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 6px 25px rgba(26, 60, 52, 0.3);
}

.action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 35px rgba(26, 60, 52, 0.6);
  background: #2A5C4E;
}

/* Media Queries */
@media (max-width: 800px) {
  .hero-title {
    font-size: clamp(2.5rem, 5vw, 4rem);
  }

  .hero-subtitle {
    font-size: clamp(0.9rem, 1.5vw, 1.3rem);
  }
}

@media (max-width: 400px) {
  .hero-title {
    font-size: clamp(2rem, 4vw, 3rem);
  }

  .hero-subtitle {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    max-width: 90%;
  }

  .action-button {
    padding: clamp(0.6rem, 1vw, 0.8rem) clamp(1rem, 2vw, 1.5rem);
    font-size: clamp(0.9rem, 1.2vw, 1rem);
  }
}

.section-title {
  background: linear-gradient(90deg, #ffd36e, #949a19) !important;
  background: linear-gradient(90deg, var(--gradient-start), var(--gradient-end)) !important;
}