.testimonial-section {
  width: 100%;
  /* max-width: 56rem; */
  padding: 2rem;
  margin: 50px auto;
}

.testimonial-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0 clamp(1rem, 2vw, 2rem);
}

.testimonial-title-dv{
  display: grid;
  place-items: center;
}

.testimonial-title-dv span:first-child {
  color: #808080;
}

.testimonial-title-dv span:last-child {
  color: #fed600;
}

.testimonial-title{
  font-size: 5rem;
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
}

.testimonial-grid {
  display: grid;
  gap: 5rem;
}

.testimonial-section .image-container {
  position: relative;
  width: 100%;
  height: 24rem;
  perspective: 1000px;
}

.testimonial-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.5rem;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-section .name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #d0d0d0;
  margin-bottom: 0.25rem;
}

.testimonial-section .designation {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 2rem;
}

.testimonial-section .quote {
  font-size: 1.125rem;
  color: #4b5563;
  line-height: 1.75;
}

.testimonial-section .arrow-buttons {
  display: flex;
  gap: 1rem;
  padding-top: 3rem;
}

.testimonial-section .arrow-button {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.testimonial-section .arrow-button {
  background: var(--card-bg);
  border: 2px solid #fed600;
}

.testimonial-section .arrow-button:hover {
  background-color: #fed600;
}

.testimonial-section .arrow-button svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: #f1f1f7;
  transition: transform 0.3s;
}

.testimonial-section .arrow-button:hover svg {
  fill: #ffffff;
}

.testimonial-section .prev-button:hover svg {
  transform: rotate(-12deg);
}

.testimonial-section .next-button:hover svg {
  transform: rotate(12deg);
}

@media (min-width: 768px) {
  .testimonial-grid {
    grid-template-columns: 1fr 1fr;
  }

  .testimonial-section .arrow-buttons {
    padding-top: 0;
  }
}