.client-section h2 {
    text-align: center;
    padding: 20px;
    margin: 50px auto;
}

/* Slider */

.client-section .slick-slide {
    margin: 0px 20px;
}

.client-section .slick-slide {
    height: 20vh;
    display: flex;
    justify-content: center;  /* Horizontal center */
    align-items: center;   
}

.client-section .slick-slide img {
    width: 50%;
    max-height: 100%;
    max-width: 100%;
}

.client-section .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.client-section .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.client-section .slick-list:focus {
    outline: none;
}

.client-section .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.client-section .slick-slider .slick-track,
.client-section .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.client-section .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    max-height: 20vh;
}

.client-section .slick-track:before,
.client-section .slick-track:after {
    display: table;
    content: '';
}

.client-section .slick-track:after {
    clear: both;
}

.client-section .slick-loading .slick-track {
    visibility: hidden;
}

.client-section .slick-slide {
    display: none;
    float: left;
}

[dir='rtl'] .client-section .slick-slide {
    float: right;
}

.client-section .slick-slide img {
    display: block;
}

.client-section .slick-slide.slick-loading img {
    display: none;
}

.client-section .slick-slide.dragging img {
    pointer-events: none;
}

.client-section .slick-initialized .slick-slide {
    display: flex;
}

.client-section .slick-loading .slick-slide {
    visibility: hidden;
}

.client-section .slick-vertical .slick-slide {
    display: block;
    border: 1px solid transparent;
}

.client-section .slick-arrow.slick-hidden {
    display: none;
}