/* Custom Cursor Styles */
.custom-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 0, 0, 0.8); /* Cursor color */
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.1s ease-out;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  .spray-effect {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(0, 255, 0, 0.8); /* Spray color */
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }
  
  .spray-effect.active {
    opacity: 1;
    animation: sprayAnimation 0.5s ease-out forwards;
  }
  
  @keyframes sprayAnimation {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(3);
      opacity: 0.6;
    }
    100% {
      transform: translate(-50%, -50%) scale(6);
      opacity: 0;
    }
  }
  