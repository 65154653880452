/* Constants */
:root {
  --background: #000000;
  --accent: #FFFFFF;
  --text-light: #FFFFFF;
  --text-dark: #1A1A1A;
  --card-bg: #1A1A1A;
  --text-muted: #808080;
  --red-glow: #FFFFFF;
  --cursor-color: #FFFFFF; /* White cursor */
  --breakpoint-small: 400px;
  --breakpoint-medium: 800px;
  --breakpoint-large: 1400px;
}

/* Keyframes */
@keyframes slideUp {
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Styles */
.section-wrapper {
  width: 100%;
  padding: clamp(2rem, 5vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  padding: 0 clamp(0.5rem, 2vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.case-studies-section {
  background: var(--background);
  animation: slideUp 3s ease-out;
  width: 100%;
  padding: clamp(2rem, 5vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  position: relative;
  padding: clamp(2rem, 4vw, 4rem) 0;
  max-height: 200vh; /* Limit to 2 screen heights, no scrolling */
}

.timeline-line {
  position: absolute;
  top: 10%; /* Start 10% from the top */
  bottom: 10%; /* End 10% from the bottom */
  left: 50%;
  width: 4px;
  background: rgba(255, 255, 255, 0.3);
  transform: translateX(-50%);
  z-index: 0;
}

.timeline-cursor {
  position: absolute;
  left: 50%;
  width: 16px;
  height: 16px;
  background: #ffd700;
  border-radius: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  z-index: 1;
  transition: transform 0.3s ease;
}

.timeline-item {
  display: flex;
  align-items: center;
  position: relative;
  margin: clamp(1rem, 2vw, 2rem) 0; /* Reduced spacing */
  opacity: 0; /* Initially hidden */
}

.timeline-item.fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.timeline-item.side-left {
  justify-content: flex-start;
}

.timeline-item.side-right {
  justify-content: flex-end;
}

.timeline-image {
  width: clamp(100px, 15vw, 150px);
  height: clamp(100px, 15vw, 150px);
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
}

.timeline-item.shade-indigo .timeline-image {
  border: 3px solid rgba(165, 180, 252, 0.5); /* #A5B4FC */
}

.timeline-item.shade-emerald .timeline-image {
  border: 3px solid rgba(167, 243, 208, 0.5); /* #A7F3D0 */
}

.timeline-item.shade-amber .timeline-image {
  border: 3px solid rgba(253, 230, 138, 0.5); /* #FDE68A */
}

.timeline-item:hover .timeline-image {
  transform: scale(1.1);
}

.timeline-content {
  background: var(--card-bg);
  padding: clamp(1rem, 2vw, 1.5rem);
  border-radius: 10px;
  max-width: clamp(300px, 40vw, 400px);
  position: relative;
  z-index: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.timeline-content::before {
  content: '';
  position: absolute;
  top: 50%;
  width: 20px;
  height: 16px;
  background: var(--card-bg);
  transform: translateY(-50%) rotate(45deg);
  z-index: -1;
}

.timeline-content.side-left::before {
  right: -10px;
}

.timeline-content.side-right::before {
  left: -10px;
}

.timeline-item:hover .timeline-content {
  transform: translateY(-5px);
}

.feature-title {
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  color: var(--text-light);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.timeline-description {
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  color: var(--text-muted);
  line-height: 1.5;
}

/* Media Queries */
@media (min-width: 801px) {
  .timeline-image.side-left {
    order: 0;
    margin-right: clamp(1rem, 2vw, 2rem);
  }

  .timeline-image.side-right {
    order: 1;
    margin-left: clamp(1rem, 2vw, 2rem);
  }
}

@media (max-width: 800px) {

  .timeline-line {
    left: 10%;
    transform: none;
  }

  .timeline-cursor {
    left: 10%;
  }

  .timeline-item {
    justify-content: flex-start;
    flex-direction: row;
  }

  .timeline-image {
    margin-right: clamp(0.5rem, 1vw, 1rem);
    margin-left: 10%;
  }

  .timeline-content::before {
    left: -10px;
    right: unset;
  }

  .feature-title {
    font-size: clamp(1.3rem, 1.8vw, 1.5rem);
  }

  .timeline-description {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
}

@media (max-width: 400px) {

  .timeline-container {
    padding: 1rem 0;
  }

  .timeline-line {
    left: 5%;
  }

  .timeline-cursor {
    left: 5%;
  }

  .timeline-item {
    margin: 1rem 0;
  }

  .timeline-image {
    width: clamp(80px, 12vw, 100px);
    height: clamp(80px, 12vw, 100px);
    margin-right: 0.5rem;
    margin-left: 5%;
  }

  .timeline-content {
    padding: clamp(0.5rem, 1vw, 1rem);
    max-width: clamp(200px, 60vw, 300px);
  }

  .feature-title {
    font-size: clamp(1.1rem, 1.5vw, 1.3rem);
  }

  .timeline-description {
    font-size: clamp(0.7rem, 1vw, 0.9rem);
  }
}