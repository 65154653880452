/* Constants */
:root {
  --background: #ffffff; /* Changed from #fdfdfd to pure white */
  --accent: #000000; /* Changed to black for titles/icons */
  --text-light: #000000; /* Changed to black for main text */
  --text-muted: #666666; /* Darkened from #B0B0B0 to #666666 for better contrast */
  --card-bg: #1E1E1E; /* Kept as is, but can change if needed */
  --highlight: #00D4FF; /* Kept cyan for accents */
  --breakpoint-small: 400px;
  --breakpoint-medium: 800px;
  --breakpoint-large: 1400px;
}

/* Keyframes */
@keyframes popUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Styles */
.showcase-section {
  width: 100%;
  min-height: 100vh;
  padding: clamp(3rem, 5vw, 5rem) 0;
  background: var(--background); /* Now pure white (#ffffff) */
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showcase-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.02) 0%, transparent 70%); /* Adjusted to dark gradient for white bg */
  z-index: 0;
}

.content-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  padding: 0 clamp(1rem, 2vw, 3rem);
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: clamp(2.5rem, 4vw, 4rem);
  font-weight: 800;
  text-align: center;
  color: var(--accent); /* Now black (#000000) */
  margin-bottom: clamp(3rem, 5vw, 5rem);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: var(--highlight);
  border-radius: 2px;
}

.grid-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Kept for left-aligned arrows */
}

.feature-grid {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: clamp(1.5rem, 3vw, 3rem);
  padding: clamp(1rem, 2vw, 2rem) 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.feature-grid::-webkit-scrollbar {
  display: none;
}

.showcase-card {
  flex: 0 0 clamp(300px, 35vw, 400px);
  background: var(--card-bg);
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  scroll-snap-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: popUp 0.5s ease-out forwards;
  animation-delay: calc(var(--index) * 0.1s);
}

.showcase-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.image-container {
  position: relative;
  width: 100%;
  height: clamp(200px, 30vw, 280px);
  overflow: hidden;
}

.showcase-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.showcase-card:hover .showcase-image {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.showcase-card:hover .image-overlay {
  opacity: 1;
  animation: fadeInOverlay 0.3s ease forwards;
}

.overlay-title {
  font-size: clamp(1.2rem, 1.8vw, 1.5rem);
  color: var(--accent); /* Now black (#000000) */
  text-align: center;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.text-container {
  padding: clamp(1rem, 1.5vw, 1.5rem);
}

.feature-title {
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  color: var(--text-light); /* Now black (#000000) */
  margin-bottom: clamp(0.5rem, 1vw, 0.8rem);
  text-align: center;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.feature-description {
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  color: var(--text-muted); /* Now #666666 for better contrast */
  text-align: center;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;
}

/* Arrow Buttons */
.arrow-button {
  background: var(--card-bg);
  border: 2px solid var(--highlight);
  border-radius: 50%;
  width: clamp(35px, 4vw, 45px);
  height: clamp(35px, 4vw, 45px);
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.arrow-button span {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: var(--highlight);
}

.arrow-button:hover {
  background: var(--highlight);
}

.arrow-button:hover span {
  color: var(--background); /* Now white (#ffffff) */
}

/* Position Arrows at Bottom Left */
.grid-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.arrow-button {
  position: static;
  margin-top: clamp(1rem, 2vw, 2rem);
}

.arrow-container {
  display: flex;
  align-items: center;
  gap: clamp(0.5rem, 1vw, 1rem);
}

.left-arrow,
.right-arrow {
  display: inline-flex;
}

/* Media Queries */
@media (max-width: 800px) {
  .showcase-section {
    padding: clamp(2rem, 4vw, 4rem) 0;
  }

  .section-title {
    font-size: clamp(2rem, 3.5vw, 3rem);
    margin-bottom: clamp(2rem, 4vw, 4rem);
  }

  .showcase-card {
    flex: 0 0 clamp(260px, 38vw, 340px);
  }

  .image-container {
    height: clamp(180px, 28vw, 240px);
  }

  .feature-title {
    font-size: clamp(1.3rem, 1.8vw, 1.5rem);
  }

  .feature-description {
    font-size: clamp(0.8rem, 1.2vw, 0.9rem);
  }

  .arrow-button {
    width: clamp(30px, 3.5vw, 40px);
    height: clamp(30px, 3.5vw, 40px);
  }

  .arrow-button span {
    font-size: clamp(0.9rem, 1.2vw, 1rem);
  }

  .arrow-container {
    gap: clamp(0.4rem, 0.8vw, 0.8rem);
  }
}

@media (max-width: 400px) {
  .section-title {
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    margin-bottom: clamp(1.5rem, 3vw, 3rem);
  }

  .feature-grid {
    gap: clamp(1rem, 2vw, 2rem);
    padding: 1rem 0;
  }

  .showcase-card {
    flex: 0 0 clamp(220px, 42vw, 300px);
  }

  .image-container {
    height: clamp(150px, 25vw, 200px);
  }

  .feature-title {
    font-size: clamp(1.1rem, 1.5vw, 1.3rem);
  }

  .feature-description {
    font-size: clamp(0.7rem, 1vw, 0.8rem);
  }

  .arrow-button {
    width: clamp(25px, 3vw, 35px);
    height: clamp(25px, 3vw, 35px);
  }

  .arrow-button span {
    font-size: clamp(0.8rem, 1vw, 0.9rem);
  }

  .arrow-container {
    gap: clamp(0.3rem, 0.6vw, 0.6rem);
  }
}