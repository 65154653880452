* {
  font-family: 'Montserrat', sans-serif;
}

body{
  background: #000;
  font-family: 'Montserrat', sans-serif;
}

.section-title {
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  color: var(--accent);
  margin-bottom: clamp(3rem, 5vw, 5rem);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #ffc800;
  border-radius: 2px;
}

@media (max-width: 400px) {
  .section-title {
    font-size: clamp(1.5rem, 3vw, 2rem);
  }
  .header-title {
    margin-top: 80px;
    font-size: clamp(1rem, 4vw, 3rem);
  }

  .header-subtitle {
    font-size: clamp(0.5rem, 1.2vw, 1rem);
    max-width: 90%;
  }

  .action-button {
    padding: clamp(0.6rem, 1vw, 0.8rem) clamp(1rem, 2vw, 1.5rem);
    font-size: clamp(0.9rem, 1.2vw, 1rem);
  }
}

@media (max-width: 800px) {
  .section-title {
    font-size: clamp(1.8rem, 3vw, 2.5rem);
  }
  .header-title {
    margin-top: 100px;
    font-size: clamp(1.5rem, 5vw, 4rem);
  }

  .header-subtitle {
    font-size: clamp(0.8rem, 1.5vw, 1.3rem);
  }

  .action-button {
    display: none;
  }
}