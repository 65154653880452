/* Header Section */
.contactus-section .header-section {
  position: relative;
  background: #000;
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 768px) {
  .contactus-section .header-section {
    height: 30vh;
  }
}

/* Video Background */
.contactus-section .video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
}

/* Content Container */
.contactus-section .content-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  padding: 0 clamp(0.5rem, 1.5vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
}

/* Header Title */
.contactus-section .header-title {
  font-weight: 900;
  background: linear-gradient(90deg, rgb(0, 154, 205), rgb(212, 160, 23)) text;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Header Subtitle */
.contactus-section .header-subtitle {
  font-size: clamp(0.9rem, 1.5vw, 1.5rem);
  color: #fff;
  max-width: 600px;
  margin-bottom: 2rem;
}

/* Action Button */
.contactus-section .action-button {
  padding: 1rem 2rem;
  background: #f6d732;
  border: none;
  border-radius: 8px;
  font-size: var(--font-size-medium);
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contactus-section .action-button:hover {
  transform: translateY(-3px);
  color: #f6d732;
  background: #fff;
  border: solid 2px #f6d732;
}
