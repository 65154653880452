/* Constants */
:root {
  --background: #000000;
  --accent: #FFFFFF;
  --text-light: #FFFFFF;
  --text-dark: #1A1A1A;
  --card-bg: #1A1A1A;
  --text-muted: #808080;
  --red-glow: #FFFFFF;
  --breakpoint-small: 400px;
  --breakpoint-medium: 800px;
  --breakpoint-large: 1400px;
}

/* Keyframes */
@keyframes slideUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes glow-indigo {
  0% { box-shadow: 0 0 5px rgba(165, 180, 252, 0.5); }
  50% { box-shadow: 0 0 15px rgba(165, 180, 252, 0.7); }
  100% { box-shadow: 0 0 5px rgba(165, 180, 252, 0.5); }
}

@keyframes glow-emerald {
  0% { box-shadow: 0 0 5px rgba(167, 243, 208, 0.5); }
  50% { box-shadow: 0 0 15px rgba(167, 243, 208, 0.7); }
  100% { box-shadow: 0 0 5px rgba(167, 243, 208, 0.5); }
}

@keyframes glow-amber {
  0% { box-shadow: 0 0 5px rgba(253, 230, 138, 0.5); }
  50% { box-shadow: 0 0 15px rgba(253, 230, 138, 0.7); }
  100% { box-shadow: 0 0 5px rgba(253, 230, 138, 0.5); }
}

/* Styles */
.section-wrapper {
  width: 100%;
  min-height: 80vh;
  padding: clamp(2rem, 5vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.content-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  padding: 0 clamp(0.5rem, 2vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.contact-section {
  background: var(--background);
  width: 100%;
  min-height: 80vh;
  padding: clamp(2rem, 5vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-split{
  animation: slideUp 2s ease-out;
}

.contact-split {
  display: flex;
  width: 100%;
  gap: clamp(1rem, 2vw, 2rem);
}

.contact-left {
  flex: 1;
  background: linear-gradient(135deg, var(--card-bg), rgba(0, 0, 0, 0.9));
  padding: clamp(1rem, 2vw, 2rem);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact-left p {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: var(--text-muted);
  line-height: 1.6;
}

.contact-right {
  flex: 1;
  padding: clamp(1rem, 2vw, 2rem);
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: clamp(0.8rem, 1.5vw, 1.5rem);
}

.input {
  padding: clamp(0.5rem, 1vw, 1rem);
  border-radius: 8px;
  border: 1px solid transparent;
  background: var(--card-bg);
  color: var(--text-light);
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  transition: all 0.3s ease;
}

.input.shade-indigo:focus {
  outline: none;
  border: 1px solid rgba(165, 180, 252, 0.7); /* #A5B4FC */
  box-shadow: 0 0 10px rgba(165, 180, 252, 0.5);
  animation: glow-indigo 1.5s infinite ease-in-out;
}

.input.shade-emerald:focus {
  outline: none;
  border: 1px solid rgba(167, 243, 208, 0.7); /* #A7F3D0 */
  box-shadow: 0 0 10px rgba(167, 243, 208, 0.5);
  animation: glow-emerald 1.5s infinite ease-in-out;
}

.input::placeholder,
.textarea::placeholder {
  color: var(--text-muted);
}

.textarea {
  padding: clamp(0.5rem, 1vw, 1rem);
  border-radius: 8px;
  border: 1px solid transparent;
  background: var(--card-bg);
  color: var(--text-light);
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  min-height: clamp(100px, 20vh, 150px);
  resize: vertical;
  transition: all 0.3s ease;
}

.textarea.shade-amber:focus {
  outline: none;
  border: 1px solid rgba(253, 230, 138, 0.7); /* #FDE68A */
  box-shadow: 0 0 10px rgba(253, 230, 138, 0.5);
  animation: glow-amber 1.5s infinite ease-in-out;
}

.submit-button {
  padding: clamp(0.5rem, 1vw, 1rem) clamp(1rem, 2vw, 2rem);
  border-radius: 8px;
  background: linear-gradient(135deg, var(--accent), rgba(255, 255, 255, 0.8));
  color: var(--text-dark);
  border: none;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), var(--accent));
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

/* Media Queries */
@media (max-width: 800px) {

  .contact-split {
    flex-direction: column;
    align-items: center;
  }

  .contact-left {
    width: 100%;
    max-width: 600px;
    padding: clamp(0.8rem, 1.5vw, 1.5rem);
  }

  .contact-right {
    width: 100%;
    max-width: 600px;
    padding: clamp(0.8rem, 1.5vw, 1.5rem);
  }
}

@media (max-width: 400px) {

  .contact-split {
    gap: 1rem;
  }

  .contact-left {
    padding: clamp(0.5rem, 1vw, 1rem);
  }

  .contact-left p {
    font-size: clamp(0.9rem, 1.2vw, 1rem);
  }

  .contact-right {
    padding: clamp(0.5rem, 1vw, 1rem);
  }

  .contact-form {
    gap: 0.8rem;
  }

  .input {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    padding: 0.5rem;
  }

  .textarea {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    min-height: 100px;
    padding: 0.5rem;
  }

  .submit-button {
    font-size: clamp(0.9rem, 1.2vw, 1rem);
    padding: 0.5rem 1rem;
  }
}