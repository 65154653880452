* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-snap-type: y proximity; /* Changed to proximity for better control */
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background: #0d0d0d;
  color: #ffffff;
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
}

h3 {
  color: #ffffff;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
}

p {
  color: #d9d9d9;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
}

small {
  color: #b3b3b3;
  font-size: clamp(0.7rem, 1vw, 0.9rem);
}

a {
  text-decoration: none;
}