:root {
  --background: #000000; /* Changed from #0A1D37 to pure black */
  --accent: #FFD700; /* Soft gold */
  --text-light: #FFFFFF; /* Crisp white */
  --text-dark: #1E2A44; /* Slightly lighter navy for contrast */
  --card-bg: #1E2A44; /* Card background */
  --text-muted: #B0B0B0; /* Muted gray for secondary text */
  --teal-glow: #FFD700; /* Soft gold for button hover */
  --breakpoint-small: 400px;
  --breakpoint-medium: 800px;
  --breakpoint-large: 1400px;
}

/* Keyframes */
@keyframes cardFadeIn {
  0% { transform: perspective(800px) rotateY(30deg) scale(0.9); opacity: 0; }
  100% { transform: perspective(800px) rotateY(0deg) scale(1); opacity: 1; }
}

@keyframes cardFadeOut {
  0% { transform: perspective(800px) rotateY(0deg) scale(1); opacity: 1; }
  100% { transform: perspective(800px) rotateY(-30deg) scale(0.9); opacity: 0; }
}

/* Styles */
.feature-section {
  width: 100%;
  min-height: 60vh;
  padding: clamp(2rem, 5vw, 6rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background); /* Now uses #000000 */
  position: relative;
  overflow: hidden;
  margin-top: clamp(2rem, 4vw, 4rem);
  z-index: 2;
}

.feature-section > * {
  position: relative;
  z-index: 1;
}

.content-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  padding: 0 clamp(0.5rem, 2vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.feature-highlight-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: clamp(800px, 90vw, 1100px);
  margin-bottom: clamp(1.5rem, 2vw, 3rem);
  padding: clamp(1rem, 2vw, 2rem);
  background: var(--card-bg);
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 215, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-highlight-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

.feature-highlight-section.fade-in {
  animation: cardFadeIn 0.9s ease-out forwards;
}

.feature-highlight-section.fade-out {
  animation: cardFadeOut 0.9s ease-out forwards;
}

.feature-highlight-image {
  width: 100%;
  max-width: clamp(250px, 35vw, 400px);
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.feature-highlight-section:hover .feature-highlight-image {
  transform: scale(1.02);
}

.feature-highlight-content {
  flex: 1;
  padding: 0 clamp(1rem, 2vw, 2rem);
}

.feature-highlight-title {
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  color: var(--text-light);
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

.feature-highlight-title.subtitle {
  font-size: clamp(0.8rem, 1.2vw, 1rem);
  color: var(--accent);
  margin-bottom: 0.4rem;
}

.feature-highlight-description {
  font-size: clamp(0.8rem, 1.2vw, 1rem);
  color: var(--text-muted);
  line-height: 1.6;
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.feature-highlight-button {
  padding: clamp(0.4rem, 0.8vw, 0.6rem) clamp(0.8rem, 1.5vw, 1.5rem);
  background: transparent;
  border: 2px solid var(--accent);
  border-radius: 5px;
  color: var(--accent);
  font-size: clamp(0.8rem, 1.2vw, 0.9rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.feature-highlight-button:hover {
  background: var(--teal-glow);
  border-color: var(--teal-glow);
  color: var(--background); /* Still uses #000000 */
}

/* Media Queries */
@media (max-width: 800px) {
  .feature-section {
    min-height: 50vh;
    margin-top: clamp(1.5rem, 3vw, 3rem);
  }

  .feature-highlight-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: clamp(0.6rem, 1vw, 1rem);
    max-width: clamp(500px, 85vw, 700px);
  }

  .feature-highlight-image {
    max-width: clamp(200px, 50vw, 300px);
    margin-bottom: clamp(0.8rem, 1.5vw, 1.5rem);
  }

  .feature-highlight-content {
    padding: 0;
  }

  .feature-highlight-title {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }

  .feature-highlight-title.subtitle {
    font-size: clamp(0.7rem, 1vw, 0.9rem);
  }

  .feature-highlight-description {
    font-size: clamp(0.7rem, 1vw, 0.9rem);
  }

  .feature-highlight-button {
    font-size: clamp(0.7rem, 1vw, 0.8rem);
    padding: clamp(0.3rem, 0.6vw, 0.5rem) clamp(0.6rem, 1vw, 1rem);
  }
}

@media (max-width: 400px) {
  .feature-section {
    min-height: 40vh;
    margin-top: clamp(1rem, 2vw, 2rem);
  }

  .feature-highlight-section {
    padding: clamp(0.4rem, 0.8vw, 0.8rem);
    margin-bottom: 1.5rem;
    max-width: clamp(300px, 90vw, 500px);
  }

  .feature-highlight-image {
    max-width: clamp(150px, 60vw, 250px);
  }

  .feature-highlight-title {
    font-size: clamp(0.9rem, 1.8vw, 1.3rem);
  }

  .feature-highlight-title.subtitle {
    font-size: clamp(0.6rem, 0.8vw, 0.8rem);
  }

  .feature-highlight-description {
    font-size: clamp(0.6rem, 0.9vw, 0.8rem);
    margin-bottom: 0.8rem;
  }

  .feature-highlight-button {
    font-size: clamp(0.6rem, 0.9vw, 0.7rem);
    padding: 0.4rem 0.8rem;
  }
}