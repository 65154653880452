/* Constants */
:root {
  --background: #000000;
  --accent: #FFFFFF;
  --text-light: #FFFFFF;
  --text-dark: #1A1A1A;
  --card-bg: #1A1A1A;
  --text-muted: #808080;
  --red-glow: #FFFFFF;
  --breakpoint-small: 400px;
  --breakpoint-medium: 800px;
  --breakpoint-large: 1400px;
}

/* Keyframes */
@keyframes slideUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Styles */
.career-section {
  background: var(--background);
  animation: slideUp 1.4s ease-out;
  width: 100%;
  min-height: 80vh;
  padding: clamp(2rem, 5vw, 5rem) 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.career-container {
  width: 100%;
  max-width: var(--breakpoint-large);
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0 clamp(1rem, 2vw, 2rem);
}

/* Left Card - Now on Right */
.career-left-card {
  background: var(--card-bg);
  padding: clamp(1.5rem, 2vw, 2rem); /* Reduced padding for a more compact look */
  border-radius: 15px;
  flex: 0 0 40%; /* Takes up 40% of the container */
  color: var(--text-light);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.career-left-card:hover {
  transform: scale(1.02);
  box-shadow: 0 5px 20px rgba(255, 255, 255, 0.1);
}

.career-title {
  font-size: clamp(1.8rem, 3vw, 2.5rem); /* Slightly smaller font size */
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.career-description {
  font-size: clamp(0.9rem, 1.2vw, 1rem); /* Adjusted font size for smaller card */
  color: var(--text-muted);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.learn-more-btn {
  background: transparent;
  border: 2px solid var(--accent);
  color: var(--accent);
  padding: 0.6rem 1.5rem; /* Slightly smaller button */
  border-radius: 25px;
  font-size: clamp(0.8rem, 1vw, 0.9rem);
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  align-self: flex-start; /* Align button to the left */
}

.learn-more-btn:hover {
  background: var(--accent);
  color: var(--text-dark);
}

/* Right Card - Now on Left */
.career-right-card {
  flex: 0 0 60%; /* Takes up 60% of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.career-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.career-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fadeIn 1s ease-in;
}

.career-tagline {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  color: var(--text-light);
  font-style: italic;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

/* Media Queries */
@media (max-width: 800px) {
  .career-container {
    flex-direction: column;
    gap: 1rem;
  }

  .career-left-card {
    flex: 1; /* Full width on smaller screens */
    padding: clamp(1rem, 1.5vw, 1.5rem);
  }

  .career-right-card {
    flex: 1; /* Full width on smaller screens */
  }

  .career-title {
    font-size: clamp(1.5rem, 2.5vw, 2rem);
  }

  .career-description {
    font-size: clamp(0.8rem, 1vw, 0.9rem);
  }

  .career-tagline {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    bottom: 1.5rem;
    right: 1.5rem;
  }
}

@media (max-width: 400px) {
  .career-title {
    font-size: clamp(1.3rem, 2vw, 1.8rem);
  }

  .career-description {
    font-size: clamp(0.7rem, 0.9vw, 0.8rem);
  }

  .learn-more-btn {
    padding: 0.5rem 1.2rem;
    font-size: clamp(0.7rem, 0.9vw, 0.8rem);
  }

  .career-tagline {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    bottom: 1rem;
    right: 1rem;
  }
}