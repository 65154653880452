.our-blog {
  position: relative;
  /* margin-top: 80px;
  padding-top: 120px; */
}

.our-blog .section-heading h2 {
  margin-right: 180px;
}

.our-blog .top-dec {
  text-align: right;
  margin-top: -80px;
}

.our-blog .top-dec img {
  max-width: 270px;
}

.our-blog .left-image {
  position: relative;
}

.our-blog .left-image img {
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

.our-blog .left-image .info {
  position: relative;
}

.our-blog .left-image .info .inner-content {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  border-radius: 20px;
  margin-right: 75px;
  position: absolute;
  margin-top: -100px;
  padding: 30px;
}

.our-blog .left-image ul li {
  display: inline-block;
  font-size: 15px;
  color: #afafaf;
  font-weight: 300;
  margin-right: 20px;
}

.our-blog .left-image ul li:last-child {
  margin-right: 0px;
}

.our-blog .left-image ul li i {
  color: #ffd700;
  font-size: 16px;
  margin-right: 8px;
}

.our-blog .left-image a{
  text-decoration: none !important;
}

.our-blog .left-image h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 20px 0px 15px 0px;
}

.our-blog .left-image .info .main-blue-button {
  position: absolute;
  bottom: -80px;
  left: 0;
}

.our-blog .right-list {
  margin-left: 30px;
}

.our-blog .right-list ul li {
  display: inline-flex;
  width: 100%;
  margin-bottom: 30px;
}

.our-blog .right-list .left-content {
  margin-right: 45px;
}

.our-blog .right-list .left-content span {
  font-size: 15px;
  color: #afafaf;
  font-weight: 300;
}

.our-blog .right-list .left-content span i {
  color: #ffd700;
  font-size: 16px;
  margin-right: 8px;
}

.our-blog .right-list .left-content a{
  text-decoration: none !important;
}

.our-blog .right-list .left-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: 20px 0px 15px 0px;
}


.our-blog .right-list .right-image img {
  width: 250px;
  border-radius: 20px;
}

.main-blue-button a {
  display: inline-block;
  background-color: #ffd700;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
}