:root {
  --background: #0A1D37;
  --accent: #FFD700;
  --text-light: #FFFFFF;
  --text-dark: #1E2A44;
  --default-color: #444444;
  --background-color: #ffffff;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInLeft {
  from { transform: translateX(-30px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.about-section {
  width: 100%;
  min-height: 60vh;
  padding: clamp(2rem, 5vw, 6rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background);
  position: relative;
  overflow: hidden;
  margin-top: clamp(2rem, 4vw, 4rem);
  z-index: 2;
}

.holderCircle {
  width: 700px;
  height: 700px;
  border-radius: 100%;
  margin: 60px auto;
  position: relative;
}

.dotCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  z-index: 20;
}

.dotCircle .itemDot {
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  background: #ffffff;
  color: #ffb300;
  border-radius: 20px;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  z-index: 3;
  cursor: pointer;
  border: 2px solid #e6e6e6;
}

.dotCircle .itemDot .forActive {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.dotCircle .itemDot .forActive::after {
  content: '';
  width: 5px;
  height: 5px;
  border: 3px solid #ffb300;
  bottom: -31px;
  left: -14px;
  filter: blur(1px);
  position: absolute;
  border-radius: 100%;
}

.dotCircle .itemDot .forActive::before {
  content: '';
  width: 6px;
  height: 6px;
  filter: blur(5px);
  top: -15px;
  position: absolute;
  transform: rotate(-45deg);
  border: 6px solid #ffd700;
  right: -39px;
}

.dotCircle .itemDot.active .forActive {
  display: block;
}

.round {
  position: absolute;
  left: 45px;
  top: 45px;
  width: 580px;
  height: 580px;
  border: 2px dotted #ffd700;
  border-radius: 100%;
  -webkit-animation: rotation 100s infinite linear;
}

.dotCircle .itemDot:hover,
.dotCircle .itemDot.active {
  color: #ffffff;
  transition: 0.5s;
  background: #ffb300;
  background: -moz-linear-gradient(left, #ffb300 0%, #ffd700 100%);
  background: -webkit-linear-gradient(left, #ffb300 0%, #ffd700 100%);
  background: linear-gradient(to right, #ffb300 0%, #ffd700 100%);
  border: 2px solid #ffffff;
  -webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13);
  -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13);
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13);
}

.contentCircle {
  width: 300px;
  border-radius: 100%;
  color: #ffffff;
  position: relative;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contentCircle .CirItem {
  border-radius: 100%;
  color: #ffffff;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scale(0);
  transition: 0.5s;
  font-size: 16px;
  width: 100%;
  height: 100%;
  top: 30px;
  right: 0;
  margin: auto;
}

.CirItem.active {
  z-index: 1;
  opacity: 1;
  transform: scale(1);
  transition: 0.5s;
}

.contentCircle .CirItem i {
  font-size: 180px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -90px;
  color: #fff;
  opacity: 0.1;
}

.contentCircle .CirItem h2 {
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  margin: 10px 0 15px 0;
  font-size: 22px;
  color: #ffffff;
}

.contentCircle .CirItem p {
  font-size: 17px;
  line-height: 2em;
  color: #ffffff;
}

.about-col {
  text-align: center;
}

.about-col.fade-in {
  animation: fadeIn 0.9s ease-out forwards;
}

.about-col.fade-out {
  opacity: 0;
}

.about-col i {
  color: #ffb300;
  font-size: 50px;
  line-height: 80px;
}

.about-col h2 {
  font-weight: 600;
  letter-spacing: 2px;
  margin: 10px 0 15px 0;
  font-size: 22px;
  color: #ffffff;
}

.about-col p {
  color: #ffffff;
  line-height: 2em;
  font-size: 17px;
  margin-bottom: 0;
}

@media only screen and (min-width:300px) and (max-width:599px) {
  .about-section {
    min-height: 50vh;
    margin-top: clamp(1.5rem, 3vw, 3rem);
  }

  .holderCircle {
    width: 500px;
    height: 500px;
    margin: 30px auto;
  }

  .round {
    left: 35px;
    top: 35px;
    width: 430px;
    height: 430px;
  }

  .dotCircle .itemDot {
    width: 80px;
    height: 80px;
    line-height: 60px;
    font-size: 24px;
  }

  .contentCircle {
    width: 200px;
    top: 100px;
  }

  .contentCircle .CirItem h2 {
    font-size: 18px;
  }

  .contentCircle .CirItem p {
    font-size: 14px;
  }

  .contentCircle .CirItem i {
    font-size: 120px;
    margin-left: -60px;
  }

  .about-col h2 {
    font-size: 20px;
  }

  .about-col p {
    font-size: 15px;
  }

  .about-col i {
    font-size: 40px;
    line-height: 60px;
  }
}

@media (max-width: 400px) {
  .about-section {
    min-height: 40vh;
    margin-top: clamp(1rem, 2vw, 2rem);
  }

  .holderCircle {
    width: 400px;
    height: 400px;
    margin: 20px auto;
  }

  .round {
    left: 25px;
    top: 25px;
    width: 350px;
    height: 350px;
  }

  .dotCircle .itemDot {
    width: 60px;
    height: 60px;
    line-height: 50px;
    font-size: 20px;
  }

  .contentCircle {
    width: 150px;
    top: 75px;
  }

  .contentCircle .CirItem h2 {
    font-size: 16px;
  }

  .contentCircle .CirItem p {
    font-size: 12px;
  }

  .contentCircle .CirItem i {
    font-size: 100px;
    margin-left: -50px;
  }

  .about-col h2 {
    font-size: 18px;
  }

  .about-col p {
    font-size: 14px;
  }

  .about-col i {
    font-size: 35px;
    line-height: 50px;
  }
}